<script setup lang="ts">
import { useGlobalsStore } from '/@src/stores/global'
import { useFocusMunicipalitiesStore } from '/@src/stores/focusMunicipalities'
import { useMonthlyTargetsStore } from '/@src/stores/monthlyTargets'
import type {
  TargetAndValueRecord,
  TotalMonthlyTargets,
} from '/@src/types/monthly-targets'
import dayjs from 'dayjs'
import type { VIconColor } from '/@src/types/elements-ui'

const globalsStore = useGlobalsStore()
const focusMunicipalityStore = useFocusMunicipalitiesStore()
const monthlyTargetStore = useMonthlyTargetsStore()

const redFocusCount = computed<number>(() => {
  return focusMunicipalityStore.groupedMunicipalityTargetStatuses.red.matchActual
})

const redFocusTargets = computed<TotalMonthlyTargets>(() => {
  return {
    bronzeValue:
      focusMunicipalityStore.groupedMunicipalityTargetStatuses.red.normalisedTarget ?? 0,
    silverValue: -1,
    goldValue:
      focusMunicipalityStore.groupedMunicipalityTargetStatuses.red.totalTarget ?? 0,
  }
})

const redFocusTexts = {
  gold: 'Aantal personen die aan het einde van het kwartaal in rode focus-gemeentes gematcht moet zijn: {value}',
  silver: '',
  bronze:
    'Aantal personen die nu gematcht zouden moeten zijn in rode focus-gemeentes: {value}',
  value: 'Gematchte personen in rode focus-gemeentes: {value}',
}

const redFocusDisabled = {
  silver: true,
}

const localCount = computed<number>(() => {
  if (!focusMunicipalityStore.targetStatus) {
    return 0
  }

  const municipalities = Object.values(focusMunicipalityStore.targetStatus)
  return municipalities.reduce((acc, municipality) => {
    if (municipality.totalTarget && municipality.matchActual > municipality.totalTarget) {
      return acc + (municipality.totalTarget ?? 0)
    } else {
      return acc + municipality.matchActual
    }
  }, 0)
})

const localTargets = computed<TotalMonthlyTargets>(() => {
  if (!focusMunicipalityStore.targetStatus) {
    return {
      bronzeValue: 0,
      silverValue: -1,
      goldValue: 0,
    }
  }
  const municipalities = Object.values(focusMunicipalityStore.targetStatus)
  return municipalities.reduce(
    (acc, municipality) => {
      return {
        bronzeValue: acc.bronzeValue + (municipality.normalisedTarget ?? 0),
        silverValue: -1,
        goldValue: acc.goldValue + (municipality.totalTarget ?? 0),
      }
    },
    {
      bronzeValue: 0,
      silverValue: -1,
      goldValue: 0,
    },
  )
})

const localTexts = {
  gold: 'Lokaal: Aantal personen die aan het einde van het kwartaal gematcht moet zijn: {value}',
  silver: '',
  bronze: 'Lokaal: Aantal personen die nu gematcht zouden moeten zijn: {value}',
  value: 'Lokaal: Gematchte personen: {value}',
}

const localDisabled = {
  silver: true,
}

const nationCount = computed<number>(() => {
  return (globalsStore.newMatchesCount ?? 0) - localCount.value
})

const nationTargets = computed<TotalMonthlyTargets>(() => {
  const totalTarget = monthlyTargetStore.currentYearlyTotalTarget

  const weekNumber = dayjs().week()

  return {
    bronzeValue: (weekNumber / 52) * totalTarget.bronzeValue,
    silverValue: -1,
    goldValue: totalTarget.bronzeValue,
  }
})

const nationTexts = {
  gold: 'Landelijk: Aantal personen die aan het einde van het kwartaal gematcht moet zijn: {value}',
  silver: '',
  bronze: 'Landelijk: Aantal personen die nu gematcht zouden moeten zijn: {value}',
  value: 'Landelijk: Gematchte personen: {value}',
}

const nationDisabled = {
  silver: true,
}

const getValueBarColor = (
  value: number,
  targets: TotalMonthlyTargets,
  disableTargets: Partial<TargetAndValueRecord<boolean>>,
): VIconColor => {
  if (!value || value < targets.bronzeValue * 0.9) {
    return 'danger'
  } else if (value < targets.bronzeValue) {
    return 'warning'
  } else if (value < targets.silverValue) {
    return 'bronze'
  } else if (value < targets.goldValue) {
    if (disableTargets?.silver) {
      return 'bronze'
    } else {
      return 'silver'
    }
  } else {
    return 'gold'
  }
}
</script>

<template>
  <div>
    <slot
      name="bar-1"
      :value="redFocusCount"
      :targets="redFocusTargets"
      :texts="redFocusTexts"
      :color="getValueBarColor(redFocusCount, redFocusTargets, redFocusDisabled)"
      :disabled="redFocusDisabled"
    />

    <slot
      name="bar-2"
      :value="localCount"
      :targets="localTargets"
      :texts="localTexts"
      :color="getValueBarColor(localCount, localTargets, localDisabled)"
      :disabled="localDisabled"
    />

    <slot
      name="bar-3"
      :value="nationCount"
      :targets="nationTargets"
      :texts="nationTexts"
      :color="getValueBarColor(nationCount, nationTargets, nationDisabled)"
      :disabled="nationDisabled"
    />
  </div>
</template>

<style scoped lang="scss"></style>
