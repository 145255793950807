<script setup lang="ts">
import { computed } from 'vue'
import type { AuthUser } from '/@src/types/auth'
import { useAuthStore } from '/@src/stores/auth'
import { toast } from 'vue-sonner'
import VDropdownExtended from '/@src/components/atoms/dropdown/VDropdownExtended.vue'
import type { DropdownElement, VTabsItem } from '/@src/types/elements-ui'
import { UserRoleEnum } from '/@src/types/users'
import { isDeveloper, operatingSystem } from '/@src/utils/entity-checks'
import { useModal } from '/@src/composable/useModal'

type AdminUserDropdownEmits = {
  error: []
  train: []
  search: []
  enps: []
}

interface AdminUserDropdownProps {
  hideOptions: boolean
}

const emits = defineEmits<AdminUserDropdownEmits>()
const props = defineProps<AdminUserDropdownProps>()

const authStore = useAuthStore()

const route = useRoute()
const router = useRouter()

const selectedTab = ref<TabsKeys>('dropdown')

const authUser = computed<AuthUser | undefined>(() => authStore.user)
const isMatchmaker = computed(() => authStore.hasRole(UserRoleEnum.Matchmaker))

const isDev = import.meta.env.DEV

const localHostRoute = computed(() => {
  const fullPath = route.fullPath

  return `https://localhost:3000${fullPath}`
})

const productionRoute = computed(() => {
  const fullPath = route.fullPath

  return `https://admin.thuisgekookt.nl${fullPath}`
})

const handleLogout = async () => {
  const isAdminMode = !!authStore.adminUser

  await authStore.logOut()

  toast.success('Uitgelogd.')

  if (!isAdminMode) {
    await router.push({
      name: '/login',
      query: { redirect: route.fullPath },
    })
  }
}

const searchTagText = computed(() => {
  if (operatingSystem.value === 'macOS') {
    return '⌘K'
  } else {
    return 'Ctrl K'
  }
})

const { modalIsOpen: searchModalIsOpen } = useModal()

type DropdownElementKeys = 'error' | 'train' | 'search' | 'enps'

const dropdownElements: DropdownElement<DropdownElementKeys>[] = [
  {
    key: 'error',
    title: 'Bug report maken',
    icon: 'fa-car-side',
    disabled: props.hideOptions,
    onClick: () => emits('error'),
  },
  {
    key: 'train',
    title: 'BTW Rekentool',
    icon: 'fa-calculator',
    disabled: props.hideOptions,
    onClick: () => emits('train'),
  },
  {
    key: 'search',
    title: 'Zoeken',
    icon: 'fa-search',
    disabled: props.hideOptions,
    onClick: () => emits('search'),
  },
  {
    key: 'enps',
    title: 'e-NPS',
    icon: 'fa-square-poll-vertical',
    disabled: props.hideOptions,
    onClick: () => emits('enps'),
  },
]

type TabsKeys = 'dropdown' | 'matchmaker'
const tabs: VTabsItem<TabsKeys>[] = [
  {
    value: 'dropdown',
    label: 'Links',
  },
  {
    value: 'matchmaker',
    label: 'Matchmakers',
  },
]
</script>
<template>
  <VDropdownExtended
    class="profile-dropdown"
    :elements="selectedTab === 'dropdown' ? dropdownElements : []"
    :skidding="-128"
  >
    <div class="is-trigger dropdown-trigger">
      <VAvatar :picture="authUser?.avatar" />
    </div>

    <GlobalSearchModal v-model:open="searchModalIsOpen" />

    <template #pre-popper>
      <div class="dropdown-head">
        <VAvatar size="large" :picture="authUser?.avatar" />

        <div class="meta">
          <span>{{ authUser?.firstName ?? 'Onbekend' }}</span>
        </div>
      </div>
      <VTabs
        v-if="isMatchmaker || isDeveloper"
        v-model:selected="selectedTab"
        :tabs="tabs"
        align="centered"
      />
    </template>

    <template #popper>
      <template v-if="selectedTab === 'dropdown'">
        <template v-if="isDeveloper">
          <a
            v-if="isDev"
            class="dropdown-item is-media is-clickable"
            :href="productionRoute"
            target="_blank"
          >
            <VIcon icon="fa-laptop" />
            <div class="meta">
              <span>Ga naar productie</span>
            </div>
          </a>
          <a
            v-else
            class="dropdown-item is-media is-clickable"
            :href="localHostRoute"
            target="_blank"
          >
            <VIcon icon="fa-laptop" />
            <div class="meta">
              <span>Open in localhost</span>
            </div>
          </a>
        </template>

        <hr class="dropdown-divider" />

        <div class="dropdown-item is-button">
          <VButton
            class="logout-button"
            icon-right="fa-arrow-right-from-bracket"
            color="primary"
            width="full"
            @click.prevent="handleLogout"
          >
            {{ !!authStore.adminUser ? 'Teruggaan' : 'Uitloggen' }}
          </VButton>
        </div>
      </template>

      <MatchmakerProfileDropdown
        v-if="selectedTab === 'matchmaker' && (isMatchmaker || isDeveloper)"
      />
    </template>
  </VDropdownExtended>
</template>

<style lang="scss" scoped>
.dropdown-head {
  display: flex;
  align-items: center;
  padding: 20px 16px;

  .v-avatar {
    cursor: default;
    user-select: none;
  }

  .meta {
    margin-left: 12px;
    font-family: var(--font);

    span {
      display: block;

      &:first-child {
        font-size: 1.1rem;
        font-weight: 500;
        color: var(--dark-text);
        line-height: 1.2;
      }

      &:nth-child(2) {
        text-transform: uppercase;
        color: var(--light-text);
        font-size: 0.7rem;
      }
    }
  }
}
</style>
