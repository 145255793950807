import { z, type ZodNullableType, type ZodOptionalType, type ZodType } from 'zod'
import dayjs, { Dayjs } from 'dayjs'
import { omit } from 'lodash'
import type {
  AbstractId,
  RecursiveKeyOf,
  RecursiveRelationKeys,
  UseFieldPath,
} from '/@src/types/utils'
import type { Path } from 'vee-validate'

export type MaybeZodEffectType<T extends z.ZodTypeAny> = z.ZodEffects<T> | T
type OptionalZod<ModelKey> = ZodOptionalType<ZodType<ModelKey>>
type NullableZod<ModelKey> = ZodNullableType<ZodType<ModelKey>>
type NullableOptional<ModelKey> = ZodNullableType<ZodOptionalType<ZodType<ModelKey>>>

// Source: https://github.com/colinhacks/zod/issues/2084
export type Implements<Model> = {
  [key in keyof Model]-?: undefined extends Model[key]
    ? null extends Model[key]
      ? MaybeZodEffectType<NullableOptional<Model[key]>>
      : MaybeZodEffectType<OptionalZod<Model[key]>>
    : null extends Model[key]
      ? MaybeZodEffectType<NullableZod<Model[key]>>
      : MaybeZodEffectType<ZodType<Model[key]>>
}

export type ZodSchemaType<M = never> = {
  [key in keyof M]: Implements<M>[key]
}

export function implement<Model = never>() {
  return {
    with: (schema: ZodSchemaType<Model>) => z.object(schema),
  }
}

export interface FormWrapperProps<Model = never> {
  id: string
  schema: Record<UseFieldPath<Model>, string>
  errors: () => Partial<Record<Path<Model>, string | undefined>>
}

/**
 * Gets keys from zod schema recursively
 * @src https://github.com/colinhacks/zod/discussions/2134#discussioncomment-6581986
 * @param schema
 */
export function getZodSchemaKeys(schema: z.ZodType): string[] {
  // Adjusted: Signature now uses Zod.ZodType to eliminate null& undefined check
  if (schema instanceof z.ZodEffects) {
    return getZodSchemaKeys(schema._def?.schema ?? schema)
  }
  // check if schema is nullable or optional
  else if (schema instanceof z.ZodNullable || schema instanceof z.ZodOptional) {
    return getZodSchemaKeys(schema.unwrap())
  }
  // check if schema is an array
  else if (schema instanceof z.ZodArray) {
    return getZodSchemaKeys(schema.element)
  }
  // check if schema is an object
  else if (schema instanceof z.ZodObject) {
    // get key/value pairs from schema
    const entries = Object.entries<z.ZodType>(schema.shape) // Adjusted: Uses Zod.ZodType as generic to remove instanceof check. Since .shape returns ZodRawShape which has Zod.ZodType as type for each key.
    // loop through key/value pairs
    return entries.flatMap(([key, value]) => {
      // get nested keys
      const nested = getZodSchemaKeys(value).map((subKey) => `${key}.${subKey}`)
      // return nested keys
      return nested.length ? nested : key
    })
  }
  // return empty array
  return []
}

function requiredError(errorType?: string) {
  const errorObj = { required_error: 'Dit veld is verplicht' }

  if (errorType) {
    Object.assign(errorObj, {
      invalid_type_error: `Vul een ${errorType} in`,
    })
  }

  return errorObj
}

export const zodErrors = {
  required: requiredError,
  min: (amount: number, type: string = 'aantal') =>
    `Het ${type} moet minimaal ${amount} zijn`,
  max: (amount: number, type: string = 'aantal') =>
    `Het ${type} mag maximaal ${amount} zijn`,
  emptyString: (type?: string) =>
    !!type ? `Vul een ${type} in` : requiredError().required_error,
  maxString: (amount: number, type: string = 'De tekst') =>
    `${type} mag maximaal ${amount} karakters lang zijn`,
  int: () => 'Vul een geheel getal in',
  number: () => 'Vul een getal in',
  date: (type: string = 'datum') => `Vul een ${type} in`,
  email: () => 'Dit is een ongeldig e-mailadres',
  emoji: () => 'Voer een emoji in',
  multipleOf: (amount: number | bigint) => `Voer een veelvoud van ${amount} in`,
} as const

// Source: https://github.com/colinhacks/zod/discussions/1259
const DayJSZod = dayjs as unknown as typeof Dayjs

export function dayJSZod(message?: string) {
  return z
    .instanceof(DayJSZod, {
      message: message ?? zodErrors.date(),
    })
    .refine((date) => date.isValid(), message ?? zodErrors.date())
}

export function nullableDayJSZod(message?: string) {
  return dayJSZod(message).nullable()
}

export function nullableBooleanZod() {
  return z.union([z.literal(0), z.literal(1), z.literal(2)])
}

/**
 * Removes keys specified by the keys variable. For example, can be used to remove the `id` field.
 * Returns an object that is ready to use with the `resetForm` function from vee-validate.
 *
 * Warning: The return type is destructive, so you lose autocompletion, do not use the returned
 * object for anything but the `resetForm` function.
 *
 * @param model
 * @param keys
 */
export function removeKeysForResetForm<T extends object>(
  model: T,
  keys: RecursiveKeyOf<T>[],
): { values: Omit<T, keyof T> } {
  return {
    values: omit(model, keys) as Omit<T, keyof T>,
  }
}

/**
 * Creates empty validation for a specific type.
 * Usually this should _not_ be used, but in this case
 * we also validate the parameters on the backend.
 * This way we do get type information / autocompletion.
 */
export function reportFilterValidation<T>() {
  return z.custom<T>()
}

export function idValidation<T extends AbstractId<string>>(message?: string) {
  return z.custom<T>((value) => typeof value === 'number', {
    message: message,
  })
}
