import {
  AbstractPaymentInfo,
  type NeedsAnyPaymentInfoCheck,
  type PriceFromMatchObject,
  type PriceFromMealObject,
  type RolePaymentInfoCheck,
} from '/@src/utils/payment-info/AbstractPaymentInfo'
import type { UserTypeEnum } from '/@src/types/users'
import { UserRoleEnum } from '/@src/types/users'
import {
  calculateCommissionFromMeal,
  calculateMealPriceFromMatch,
  calculateTotalCommissionFromMeal,
} from '/@src/utils/payments'
import { MutualIcon } from '/@src/models/standardIcons'
import type { BulmaColor, FAIconName } from '/@src/types/elements-ui'
import { type BasePaymentMethod, PaymentMethodEnum } from '/@src/types/meals'

export class MutualPaymentInfo extends AbstractPaymentInfo {
  static readonly name = 'Onderling'
  static readonly icon = MutualIcon as FAIconName
  static readonly color = 'info' as BulmaColor

  readonly showCommissionInfo = true

  getName(commissionType: UserTypeEnum, noSuffix: boolean = false): string {
    if (noSuffix) {
      return 'Onderling'
    }
    const userString =
      commissionType === UserRoleEnum.Cook ? 'thuiskok' : 'maaltijdzoeker'
    return `Onderling (${userString})`
  }
  getIcon(): FAIconName {
    return MutualIcon as FAIconName
  }
  getColor(): BulmaColor {
    return 'info'
  }

  getBasePaymentMethod(): BasePaymentMethod {
    return PaymentMethodEnum.Mutual
  }

  isDonation(): boolean {
    return false
  }

  getCommissionType(commissionType: UserTypeEnum, hasCommission: boolean): string {
    if (!hasCommission) {
      return ''
    }

    const userString =
      commissionType === UserRoleEnum.Cook ? 'thuiskok' : 'maaltijdzoeker'
    return `(${userString})`
  }

  needsAnyPaymentInfo(params: NeedsAnyPaymentInfoCheck): boolean {
    if (params.price === 0) {
      return false
    }

    return !params.isPrepaid && params.hasCommission
  }

  roleNeedsPaymentInfo(params: RolePaymentInfoCheck): boolean {
    if (params.price === 0 || params.noCommission) {
      return false
    } else if (params.role !== params.commissionType) {
      return false
    }

    return !params.isPrepaid
  }

  getPricePerPortionFromMeal(role: UserTypeEnum, meal: PriceFromMealObject): number {
    if (meal.commissionType !== role) {
      return 0
    }
    return calculateCommissionFromMeal(meal.price)
  }

  getPriceFromMeal(role: UserTypeEnum, meal: PriceFromMealObject): number {
    if (role === meal.commissionType) {
      return calculateTotalCommissionFromMeal(
        meal.price,
        meal.portions,
        meal.amountOfPeople,
        meal.hasCommission,
      )
    } else {
      return 0
    }
  }

  getActualPriceFromMeal(role: UserTypeEnum, meal: PriceFromMealObject): number {
    const price = this.getPriceFromMeal(role, meal)
    return -price
  }

  getBalanceMutationFromMatch(role: UserTypeEnum, match: PriceFromMatchObject): number {
    const mealPrice = calculateMealPriceFromMatch(match.price)

    return this.getActualPriceFromMeal(role, {
      price: mealPrice,
      portions: match.portions,
      amountOfPeople: match.amountOfPeople,
      commissionType: match.commissionType,
      hasCommission: match.hasCommission,
    })
  }
}
