import { acceptHMRUpdate, defineStore } from 'pinia'
import { useApi } from '/@src/composable/useApi'
import type {
  MonthlyTarget,
  MonthlyTargetParams,
  MonthlyTargetSurveyRatings,
  TotalMonthlyTargets,
} from '/@src/types/monthly-targets'
import dayjs, { Dayjs } from 'dayjs'
import { useWebSocket } from '/@src/composable/useWebSocket'
import type { WebSocketsModelReply } from '/@src/types/webSockets'
import { updateModel } from '/@src/utils/webSockets'
import { toast } from 'vue-sonner'
import type { MaybeRefOrGetter } from 'vue'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import { dayjsUTC } from '/@src/utils/date-formatter'

dayjs.extend(quarterOfYear)

const api = useApi()

const currentYear = dayjsUTC().year()

export const useMonthlyTargetsStore = defineStore('monthlyTargets', () => {
  const monthlyTargets = ref<MonthlyTarget[]>()

  useWebSocket({
    event: '.MonthlyTargetUpdatedOrCreated',
    callback: (newModel: WebSocketsModelReply<MonthlyTarget>) => {
      const foundModel = monthlyTargets.value?.find(
        (m) => m.year === newModel.model.year && m.month === newModel.model.month,
      )

      if (foundModel) {
        updateModel(foundModel, newModel.model)
      } else {
        monthlyTargets.value?.push(newModel.model)
      }
    },
    channel: 'tg-admin-channel-monthly-targets',
    cancelOnUnmounted: false,
  })

  const updateMonthlyTarget = async (params: MonthlyTargetParams): Promise<boolean> => {
    try {
      const result = await api.put('admin/monthly-targets/update', params)
      toast.success('Maandelijks doel aangepast')
      return !!result
    } catch {
      toast.error('Maandelijks doel aanpassen mislukt')
      return false
    }
  }

  const currentYearMonthlyTargets = computed(() => getMonthlyTargets(currentYear))
  const currentQuarterlyTotalTarget = computed(() =>
    getTargetsInRange(dayjsUTC().startOf('quarter'), dayjsUTC().endOf('quarter')),
  )
  const currentYearlyTotalTarget = computed(() =>
    getTargetsInRange(dayjsUTC().startOf('year'), dayjsUTC().endOf('year')),
  )

  const getMonthlyTargets = (year: number): MonthlyTarget[] => {
    return (
      monthlyTargets.value
        ?.filter((target) => target.year === year)
        .toSorted((t1, t2) => (t1.month < t2.month ? -1 : 1)) ?? []
    )
  }

  const getTargetsFromDates = (
    fromDayjsRef: MaybeRefOrGetter<Dayjs | null | undefined>,
    toDayjsRef: MaybeRefOrGetter<Dayjs | null | undefined>,
  ): MonthlyTarget[] => {
    const fromDayjs = toValue(fromDayjsRef)
    const toDayjs = toValue(toDayjsRef)

    if (!fromDayjs || !toDayjs || !monthlyTargets.value) {
      return []
    }

    const from = { year: fromDayjs.year(), month: fromDayjs.month() + 1 }
    const to = { year: toDayjs.year(), month: toDayjs.month() + 1 }

    return monthlyTargets.value.filter((t) => {
      // Easy check
      if (from.month <= to.month) {
        return (
          t.year >= from.year &&
          t.month >= from.month &&
          t.year <= to.year &&
          t.month <= to.month
        )
      } else {
        // From somewhere in the middle of the year to a next year
        return (
          (t.year === from.year && t.month >= from.month) ||
          (t.year === to.year && t.month <= to.month) ||
          (t.year > from.year && t.year < to.year)
        )
      }
    })
  }

  const getTargetsInRange = (
    fromDayjsRef: MaybeRefOrGetter<Dayjs>,
    toDayjsRef: MaybeRefOrGetter<Dayjs>,
  ): TotalMonthlyTargets => {
    if (!monthlyTargets.value) {
      return {
        goldValue: 0,
        silverValue: 0,
        bronzeValue: 0,
      }
    }

    const targets = getTargetsFromDates(fromDayjsRef, toDayjsRef)

    return targets.reduce<TotalMonthlyTargets>(
      (acc, cur) => {
        return {
          goldValue: acc.goldValue + cur.goldValue,
          silverValue: acc.silverValue + cur.silverValue,
          bronzeValue: acc.bronzeValue + cur.bronzeValue,
        }
      },
      {
        goldValue: 0,
        silverValue: 0,
        bronzeValue: 0,
      },
    )
  }

  const getSurveyRatingTargetsInRange = (
    fromDayjsRef: MaybeRefOrGetter<Dayjs | undefined | null>,
    toDayjsRef: MaybeRefOrGetter<Dayjs | undefined | null>,
  ): MonthlyTargetSurveyRatings | null | undefined => {
    const targets = getTargetsFromDates(fromDayjsRef, toDayjsRef)
    return targets
      .toSorted((targetA, targetB) => targetB.month - targetA.month)
      .find((t) => !!t.surveyRatings)?.surveyRatings
  }

  return {
    monthlyTargets,
    updateMonthlyTarget,
    currentYearMonthlyTargets,
    currentQuarterlyTotalTarget,
    currentYearlyTotalTarget,
    getMonthlyTargets,
    getTargetsInRange,
    getSurveyRatingTargetsInRange,
  }
})

/**
 * Pinia supports Hot Module replacement, so you can edit your stores and
 * interact with them directly in your app without reloading the page.
 *
 * @see https://pinia.esm.dev/cookbook/hot-module-replacement.html
 * @see https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMonthlyTargetsStore, import.meta.hot))
}
